<template>
  <div>
    <start-application v-if="quotePaymentStep == 'confirm'" />
    <payment-details v-if="quotePaymentStep == 'confirm'" />
    <application-success v-if="quotePaymentStep == 'success'" />
  </div>
</template>

<script>
import PaymentDetails from "@/view/pages/pmi/quote-payment/PaymentDetails.vue";
import ApplicationSuccess from "@/view/pages/pmi/quote-payment/ApplicationSuccess.vue";
import StartApplication from "@/view/pages/pmi/quote-application/StartApplication.vue";


export default {
  name: "quote-payment",
  components: {
    PaymentDetails,
    ApplicationSuccess,
    StartApplication
  },
  computed: {
    quotePaymentStep: {
      get() {
        return this.$store.state.pmi.quotePaymentStep;
      }
    }
  }
}
</script>
